import React from 'react';
import '../styles/referenceStyle.css';

// Import each image
import oclcImg from '../assets/references/oclc.png';
import accentureImg from '../assets/references/accenture.png';
import wolfImg from '../assets/references/wolf.png';
import washuImg from '../assets/references/washu.png';
import siaaImg from '../assets/references/siaa.png';

export default function References() {

  const referencesData = [
    {
      text: "Ziqian brings a unique combination of front-end development skills, curiosity and respect for other people’s access methods, and a deep commitment to user research and human-centered digital experiences. His ability to synthesize findings into actionable recommendations and communicate effectively has directly improved the accessibility of products used by thousands of library members in over 100 countries.",
      imgSrc: oclcImg,
      name: "Elizabeth Neal",
      title: "Accessibility Strategist @OCLC"
    },
    {
      text: "His commitment to quality was evident in every task he undertook, consistently exceeding our expectations. Beyond his impressive technical skills, what stands out about Ziqian is his exceptional gift for ideation, project design, and software development. He's a proactive learner, always seeking ways to transform his innovative ideas into tangible results.",
      imgSrc: accentureImg,
      name: "Yawen Zhang",
      title: "Project Manager @Accenture"
    },
    {
      text: "I had the pleasure of working with Ziqian on numerous projects. The reason I always returned to collaborate with him is his total reliability, strong sense of design, and intelligence. There was no friction in communication, and the way our ideas bounced off each other always sparked something unique in the projects we completed together.",
      imgSrc: wolfImg,
      name: "Boxuan Chen",
      title: "Founder @Wolf.c Studio"
    },
    {
      text: "Ziqian quickly grasps complex concepts, communicates effectively, and is proactive in tackling challenges. His contributions to our challenging research project were invaluable, showcasing his strong learning ability and collaborative spirit. He's exactly the kind of person you want on your team.",
      imgSrc: washuImg,
      name: "Jingwen Zhang",
      title: "Researcher @WashU"
    },
    {
      text: "Ziqian is an incredible designer and a great team player. Despite his young age, he's very professional and has a great work ethic. He also has an amazing ability to empathize closely with the user, especially those with sensory disabilities in our project.",
      imgSrc: siaaImg,
      name: "Lan Chen",
      title: "Project Manager @SIAA"
    }
  ];

  return (
    <div id="references">
      <div className="reference-container">
        <div className={`reference-grids ${(referencesData.length + 1)%3 === 0 && "three-dup"}`}>
          {/* First static ref-grid */}
          <div className="ref-grid">
            <h3>His work speaks for itself through the voices of <span className="highlight">those he worked with.</span></h3>
          </div>

          {/* Render remaining ref-grid elements dynamically */}
          {referencesData.map((reference, index) => (
            <div className="ref-grid" key={index}>
              <div className="refer-text">
                <p>{reference.text}</p>
              </div>
              <div className="refer-info">
                <img src={reference.imgSrc} alt={`${reference.name}-logo`} />
                <div className="refer-details">
                  <p>{reference.name}</p>
                  <p>{reference.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
