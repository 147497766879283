import React from 'react';
import '../styles/navbarStyle.css';
import logo from '../assets/hero/hero.svg';

export default function Navbar({ placeholder = false }) {
  
  const style = {
    opacity: placeholder ? 0 : 1,
    pointerEvents: placeholder ? 'none' : 'auto',
    position: placeholder ? 'static' : 'fixed',
    marginTop: placeholder ? '-0rem' : '0',
  };

  const scrollToSection = (sectionId) => (event) => {
    event.preventDefault();  // Prevent the default anchor behavior
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="navbar" style={style} aria-hidden={placeholder}>
      <a className="navbar-left" href="#hero" title="scroll to top" onClick={scrollToSection('hero')}>
        <img src={logo} alt={placeholder ? "" : "logo"} className="logo" />
        <h1>Ziqian Wang</h1>
      </a>
      <div className="navbar-right">
        <a href="#about" tabIndex={placeholder ? -1 : 0} title="scroll to about section" onClick={scrollToSection('about')}>About.</a>
        <a href="#projects" tabIndex={placeholder ? -1 : 0} title="scroll to projects section" onClick={scrollToSection('projects')}>Projects.</a>
        <a href="#contact" tabIndex={placeholder ? -1 : 0} title="scroll to contact section" onClick={scrollToSection('contact')}>Contact.</a>
      </div>
    </div>
  );
}
