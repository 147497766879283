import React, { useState } from 'react';
import '../styles/heroStyle.css';
import Navbar from './navbar';
import Video from './video';
import design from '../assets/hero/design.svg';
import develop from '../assets/hero/develop.svg';
import arrow from '../assets/hero/arrow.svg';

export default function Hero({ onLoaded, showScrollDown }) {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
    if (onLoaded) {
      onLoaded(); // Notify Dashboard that Hero (with video) has loaded
    }
  };

  const scrollToSection = (sectionId) => (event) => {
    event.preventDefault();  // Prevent the default anchor behavior
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div id="hero">
      <Navbar placeholder={true} />
      <div className="hero-content">
        <div className="hero-left">
          <div className="hero-text">
            <p>Are you looking for somebody to</p>
            <div className="big-text">
              <img src={design} alt="design &" />
              <img src={develop} alt="development" />
            </div>
            <p>a website / mobile app / digital experience?</p>
            <p className="highlight">This is the person you are looking for.</p>
          </div>
          <div className="hero-footer">
            {showScrollDown && (
              <a href="#about" className="scroll-down-btn" onClick={scrollToSection('about')}>
                <div className="arrow-square">
                  <img src={arrow} alt="arrow" />
                </div>
                <p>SCROLL DOWN</p>
              </a>
            )}
          </div>
        </div>
        <Video onVideoLoaded={handleVideoLoaded} />
      </div>
    </div>
  );
}
